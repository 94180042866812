@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #ddd;
}

#root {
  height: 100vh;
  width: 100vw;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media print {
  .no-print {
    visibility: hidden;
  }
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  text-decoration: none;
  color: #07c;
}

.p-0{
  padding: 0px !important;
}

.p-4{
  padding: 16px;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.p-1{
  padding: 4px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.fw-bold {
  font-weight: bold !important;
}

.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}


