@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #ddd;
}

#root {
  height: 100vh;
  width: 100vw;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media print {
  .no-print {
    visibility: hidden;
  }
}
